import * as React from 'react';
import { Link, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

// Components
import Layout from '../../components/layout/Layout';
import PartnerCard from '../../components/card/Partner';
import PaginationBlock from '../../components/block/Pagination';

// Partners
const PartnersPage = ({ pageContext, data }) => {
  const { totalCount } = data.partners
  const pageTitle = `Partenaires - ${totalCount} partenaire${totalCount === 1 ? "" : "s"}`
  
  const headerBackgroundImageRandom = Math.floor(Math.random() * data.partners.nodes.length);
  console.log(data.partners.nodes[headerBackgroundImageRandom]);
  const headerBackgroundImage = data.partners.nodes[headerBackgroundImageRandom].frontmatter.image.cover ? data.partners.nodes[headerBackgroundImageRandom].frontmatter.image.cover.childImageSharp.fluid : null;
  const headerBackgroundImageStack = [
    headerBackgroundImage,
    `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))`,
  ].reverse()

  return (
    <Layout 
      pageTitle={`${pageTitle} | ${data.site.siteMetadata.title} - L'entreprise spécialiste du bâtiment à Roanne`}
      pageDescription={`Découvrez les partenaires de ${data.site.siteMetadata.title}. De nombreuses entreprises nous font confiance.`}
      pageKeywords="pcc bâtiment, partenaires, partenaire, entreprises, clients, roanne, coteau, plâtrerie, peinture"
      headerClassName="header header-layout-type-header-2rows"
    >
      <BackgroundImage
        Tag="section"
        className="page-title divider layer-overlay overlay-dark-8 section-typo-light bg-img-center"
        fluid={headerBackgroundImageStack}
      >
        <div className="container pt-90 pb-90">
          <div className="section-content">
            <div className="row">
              <div className="col-md-12 text-center">
                <h2 className="title text-white">Nos partenaires</h2>
                <nav role="navigation" className="breadcrumb-trail breadcrumbs">
                  <div className="breadcrumbs">
                    <span className="trail-item trail-begin">
                      <Link to={`/`}>Accueil</Link>
                    </span>
                    <span><i className="bi bi-chevron-right"></i></span>
                    <span className="trail-item trail-end active">Partenaires</span>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
      {data.partners && data.partners.nodes.length ?
        <section id="partners">
          <div className="container">
            <div className="section-content">
              <div className="tm-sc tm-sc-projects projects-style8-current-theme">
                <div className="row">
                  {data.partners.nodes.map(partner => (
                    <PartnerCard Card key={partner.id} data={partner} cardStyle="2" cardClassName="col-md-6 col-lg-6 col-xl-4 mb-30" />
                  ))}
                </div>
              </div>
            </div>
            <div>
              <PaginationBlock
                path={`partners`}
                currentPage={pageContext.currentPage}
                numPages={pageContext.numPages}
              />
            </div>
          </div>
        </section>
      : null}
    </Layout>
  )
}

export const query = graphql`
  query ($skip: Int!, $limit: Int!) {  
    site: site {
      siteMetadata {
        siteUrl
        title
        description
        keywords
        image {
          alt
        }
      }
    }
    partners: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/partners/" }, 
        frontmatter: { active: { eq: true } }
      },
      sort: {fields: frontmatter___name, order: ASC}
      limit: $limit
      skip: $skip
    ) {
      nodes {
        frontmatter {
          name
          description
          image {
            cover {
              childImageSharp {
                gatsbyImageData
                fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            card {
              childImageSharp {
                gatsbyImageData
                fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt
            credit {
              text
              link
            }
          }
          home
          active
          date(formatString: "D MMMM YYYY", locale: "fr")
        }
        id
        slug
      }
      totalCount
    }
  }
`

export default PartnersPage